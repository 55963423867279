import axios from "axios";
import router from "../router";
const service = axios.create({
  withCredentials: true,
  timeout: 0, // no timeout
  headers: {
    "content-type": "application/json",
  },
});

service.interceptors.request.use(
  (config) => {
    const authToken = sessionStorage.getItem("access_token");
    config.headers["Authorization"] = "Bearer " + authToken;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
service.interceptors.response.use(
  async function (res) {
    if (res.data.status_code === 200 || res.data.access_token) {
      console.log("successtoken");
      return res;
    }

    if (res.data.status_code === 401 || res.data.status_code === 403) {
      console.log("res.data.status_code=",res.data.status_code)
      if (router.currentRoute.meta && router.currentRoute.meta.requiresAuth) {
        console.log("res.data.status_code",res.data.status_code)
        console.log("failedtoken");
        const originalConfig = res.config;
        const token = sessionStorage.getItem("refresh_token");
        if (token && res.data) {
          if (!originalConfig._retry) {
            originalConfig._retry = true;
            const response = await axios.post(
              `${process.env.VUE_APP_USER_SERVICE}/api/v1/user/refresh-token?token=${token}`,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
            const newAccessToken = response.data.data[0].access_token;
            sessionStorage.setItem("access_token", newAccessToken);
            console.log("responseRefresh", newAccessToken);
            res.config.headers[
              "Authorization"
            ] = `Bearer ${newAccessToken}`;
            return new Promise((resolve, reject) => {
              axios
                .request(originalConfig)
                .then((response) => {
                  resolve(response);
                })
                .catch((err) => {
                  reject(err);
                });
            });
          }
        }
        return Promise.reject(res);
      } else {
        return res;
      }
    } else {
      return res;
    }
  },
  function (error) {
    // forbidden
    if (error.response.status == 403) {
      //route to forbidden page
    }

    return Promise.reject(error.response.data);
  }
);

export default service;
