import axios from "axios";
import remitteService from "@/services/RemitteService/remitteService.js";

const state = {
  beneficiaryDetail: {},
  updateRemitteData: {},
  deleteRemitte: {},
  remitteResponse: {},
  beneficaryUsers: {},
  getAllCountriesResponce: [],
  getExchangeRateResponce: [],
  getServiceChargeResponce: null,
  getConvertedAmountResponce: null,
  getOccupationsResponce: [],
  getNationalityResponce: [],
  getRemitteResponce: [],
  getRemitteCountryResponce: [],
  getRemitteMethodsResponce: [],
  getRemitteServiceChargeResponce: [],
  getConvertedTargetAmountResponce: null,
  base_url: process.env.VUE_APP_BASEURL,
  remittee_port: process.env.VUE_APP_REMITTE_PORT,
  business_user_port: process.env.VUE_APP_BUSINESS_USER_PORT,
  fund_transfer_port: process.env.VUE_APP_TRANSACATION_PORT,
};

const mutations = {
  registerRemitte(state, payload) {
    switch (payload.type) {
      case "REGISTERING_REMITTE":
        state.remitteResponse = {
          isRegistering: true,
          errorRegistering: false,
          remitteResponse: {},
        };
        break;
      case "REGISTERED_REMITTE":
        state.remitteResponse = {
          isRegistering: false,
          errorRegistering: false,
          remitteResponse: payload.data,
        };
        break;
    }
  },

  getBenficiaryUsers(state, payload) {
    switch (payload.type) {
      case "GETTING_KYC_USERS":
        state.beneficaryUsers = {
          isRegistering: true,
          errorRegistering: false,
          responseUser: {},
        };
        break;
      case "GET_BENIFICIARY_USERS":
        state.beneficaryUsers = {
          isRegistering: false,
          errorRegistering: false,
          responseUser: payload.data,
        };
        break;
    }
  },
  getRemittedDetailById(state, payload) {
    switch (payload.type) {
      case "GETTING_REMITTE_DETAIL_BY_ID":
        state.beneficiaryDetail = {
          isRegistering: true,
          errorRegistering: false,
          detail: {},
        };
        break;
      case "GET_REMITTE_DETAIL_BY_ID":
        state.beneficiaryDetail = {
          isRegistering: false,
          errorRegistering: false,
          detail: payload.data,
        };
        break;
    }
  },
  updatedRemittee(state, payload) {
    switch (payload.type) {
      case "UPDATING_REMITTE":
        state.updateRemitteData = {
          isLoading: true,
          error: false,
          update: {},
        };
        break;
      case "UPDATED_REMITTE":
        state.updateRemitteData = {
          isLoading: false,
          error: false,
          update: payload.data,
        };
        break;
    }
  },

  deleteRemittee(state, payload) {
    switch (payload.type) {
      case "DELETED_REMITTE":
        state.deleteRemitte = {
          isLoading: false,
          error: false,
          delete: payload.data,
        };
        break;
    }
  },
  getAllCountries(state, payload) {
    switch (payload.type) {
      case "GET_ALL_COUNTRIES":
        state.getAllCountriesResponce = {
          isLoging: true,
          errorRegistering: false,
          data: payload.data,
        };
        break;
    }
  },
  getExchangeRate(state, payload) {
    switch (payload.type) {
      case "GET_EXCHANGE_RATE":
        state.getExchangeRateResponce = {
          isLoging: true,
          errorRegistering: false,
          data: payload.data,
        };
        break;
    }
  },
  getServiceCharge(state, payload) {
    switch (payload.type) {
      case "GET_SERVICE_CHARGE":
        state.getServiceChargeResponce = {
          isLoging: true,
          errorRegistering: false,
          data: payload.data,
        };
        break;
    }
  },
  getConvertedAmount(state, payload) {
    switch (payload.type) {
      case "GET_CONVERTED_AMOUNT":
        state.getConvertedAmountResponce = {
          isLoging: true,
          errorRegistering: false,
          data: payload.data,
        };
        break;
    }
  },
  getConvertedTargetAmount(state, payload) {
    switch (payload.type) {
      case "GET_CONVERTED_FROM_AMOUNT":
        state.getConvertedTargetAmountResponce = {
          isLoging: true,
          errorRegistering: false,
          data: payload.data,
        };
        break;
    }
  },
  getOccupationsList(state, payload) {
    switch (payload.type) {
      case "GET_OCCUPATION_LIST":
        state.getOccupationsResponce = {
          isLoging: true,
          errorRegistering: false,
          data: payload.data,
        };
        break;
    }
  },
  getNationalityList(state, payload) {
    switch (payload.type) {
      case "GET_NATIONALITY_LIST":
        state.getNationalityResponce = {
          isLoging: true,
          errorRegistering: false,
          data: payload.data,
        };
        break;
    }
  },
  getUserRemitteList(state, payload) {
    switch (payload.type) {
      case "GET_REMITTE_LIST":
        state.getRemitteResponce = {
          isLoging: true,
          errorRegistering: false,
          data: payload.data,
        };
        break;
    }
  },
  getRemitteCountries(state, payload) {
    switch (payload.type) {
      case "GET_REMITTE_COUNTRIES":
        state.getRemitteCountryResponce = {
          isLoging: true,
          errorRegistering: false,
          data: payload.data,
        };
        break;
    }
  },
  getRemitteMethods(state, payload) {
    switch (payload.type) {
      case "GET_REMITTE_METHODS":
        state.getRemitteMethodsResponce = {
          isLoging: true,
          errorRegistering: false,
          data: payload.data,
        };
        break;
    }
  },
  getRemitteServiceCharge(state, payload) {
    switch (payload.type) {
      case "GET_REMITTE_SERVICE_CHARGE":
        state.getRemitteServiceChargeResponce = {
          isLoging: true,
          errorRegistering: false,
          data: payload.data,
        };
        break;
    }
  },
};

const getters = {
  remitte_details: (state) => {
    return state.remitteResponse;
  },
  users_list: (state) => state.beneficaryUsers,
  all_countries: (state) => state.getAllCountriesResponce,
  Exchange_Rate: (state) => state.getExchangeRateResponce,
  beneficiary_user_detail: (state) => state.beneficiaryDetail,

  updated_remittee_data: (state) => state.updateRemitteData,
};

const actions = {
  async createRemitte({ commit }, _remitteeDetails) {
    const token = sessionStorage.getItem("access_token");
    try {
      const responseData = await axios.post(
        `${process.env.VUE_APP_REMITTE_USER_SERVICE}/api/v1/bene/create-remitte`,
        _remitteeDetails,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );
      commit("registerRemitte", {
        type: "REGISTERED_REMITTE",
        data: responseData,
      });
      return responseData;
    } catch (e) {
      return e;
    }
  },
  async createBusRemitte({ commit }, _remitteeDetails) {
    const token = sessionStorage.getItem("access_token");
    try {
      const responseData = await axios.post(
        `${process.env.VUE_APP_REMITTE_USER_SERVICE}/api/v1/bus-bene/business-beneficiary`,
        _remitteeDetails,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );
      commit("registerRemitte", {
        type: "REGISTERED_REMITTE",
        data: responseData,
      });
      return responseData;
    } catch (e) {
      return e;
    }
  },
  async getRemitteDetailByID({ commit }, remitteeID) {
    try {
      const responseData = await remitteService.getRemitteDetailByID(remitteeID);
      commit("getRemittedDetailById", {
        type: "GET_REMITTE_DETAIL_BY_ID",
        data: responseData,
      });
      return responseData;
    } catch (e) {
      return e;
    }
  },

  async updateRemittee({ commit }, _remitteeDetails) {
    const remitteeId = _remitteeDetails.remitteID;
    const token = sessionStorage.getItem("access_token");
    try {
      const responseData = await axios.put(
        `${process.env.VUE_APP_REMITTE_USER_SERVICE}/api/v1/bene/update-beneficiary/${remitteeId}`,
        _remitteeDetails,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );
      commit("updatedRemittee", {
        type: "UPDATED_REMITTE",
        data: responseData,
      });
      return responseData;
    } catch (e) {
      return e;
    }
  },

  async updateBusRemittee({ commit }, _remitteeDetails) {
    const remitteeId = _remitteeDetails.remitteID;
    const token = sessionStorage.getItem("access_token");
    try {
      const responseData = await axios.put(
        `${process.env.VUE_APP_REMITTE_USER_SERVICE}/api/v1/bus-bene/business-beneficiary/${remitteeId}`,
        _remitteeDetails,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );
      commit("updatedRemittee", {
        type: "UPDATED_REMITTE",
        data: responseData,
      });
      return responseData;
    } catch (e) {
      return e;
    }
  },

  async deleteRemittee({ commit }, remitteeId) {
    try {
      const responseData = await remitteService.deleteRemittee(remitteeId);
      commit("deleteRemittee", { type: "DELETED_REMITTE", data: responseData });
      return responseData;
    } catch (e) {
      return e;
    }
  },

  async getBenficiary({ commit }) {
    console.log("store");
    try {
      const token = sessionStorage.getItem("access_token");
      const responseData = await axios.get(
        `${process.env.VUE_APP_REMITTE_USER_SERVICE}/api/v1/bene/remitter/get-remittee-list`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      commit("getBenficiaryUsers", {
        type: "GET_BENIFICIARY_USERS",
        data: responseData,
      });
      return responseData;
    } catch (e) {
      return e;
    }
  },

  async addBusinessUserKyc({ commit }, _businessDetails) {
    try {
      const token = sessionStorage.getItem("access_token");
      const responseData = await axios.post(
        `${process.env.VUE_APP_BUSINESS_USER_SERVICE}/api/v1/business_user_kyc/add_business_user_kyc`,
        _businessDetails,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );
      commit("updatePassword", { type: "UPDATE_PASSWORD", data: responseData });
      return responseData;
    } catch (e) {
      return e;
    }
  },
  async uploadBusinessDoc({ commit }, _businessUpload) {
    console.log("store", _businessUpload);
    try {
      const token = sessionStorage.getItem("access_token");
      const responseData = await axios.post(
        `http://3.111.140.40:5001/api/v1/business_user_kyc/upload_business_user_docs`,
        _businessUpload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("filesss", responseData);
      commit("updatePassword", { type: "UPDATE_PASSWORD", data: responseData });
      return responseData;
    } catch (e) {
      return e;
    }
  },

  //fund transfer
  async getAllCountries({ commit }) {
    try {
      const token = sessionStorage.getItem("access_token");
      const responseData = await axios.get(
        `${process.env.VUE_APP_TRANSACATION_SERVICE}/api/v1/exchangerates/get-all_country_data`,
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      commit("getAllCountries", {
        type: "GET_ALL_COUNTRIES",
        data: responseData,
      });
      return responseData;
    } catch (e) {
      return e;
    }
  },

  async getExchangeRate({ commit }, _ExchcangeRate) {
    try {
      const token = sessionStorage.getItem("access_token");
      const responseData = await axios.get(
        `${process.env.VUE_APP_TRANSACATION_SERVICE}/api/v1/forex_deal/get_publish_rate_country_wise/${_ExchcangeRate.target_country}`,
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      commit("getExchangeRate", {
        type: "GET_EXCHANGE_RATE",
        data: responseData,
      });
      return responseData;
    } catch (e) {
      return e;
    }
  },

  async getServiceCharge({ commit }, _serviceChargerCountry) {
    try {
      const token = sessionStorage.getItem("access_token");
      const responseData = await axios.get(
        `${process.env.VUE_APP_TRANSACATION_SERVICE}/api/v1/service_charge/get-service-charge/country/${_serviceChargerCountry}`,
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      commit("getServiceCharge", {
        type: "GET_SERVICE_CHARGE",
        data: responseData,
      });
      return responseData;
    } catch (e) {
      return e;
    }
  },
  async getConvertedAmount({ commit }, _ConvertedAmountRequest) {
    try {
      const token = sessionStorage.getItem("access_token");
      const responseData = await axios.get(
        `${process.env.VUE_APP_TRANSACATION_SERVICE}/api/v1/exchangerates/convert/${_ConvertedAmountRequest.source_country}/${_ConvertedAmountRequest.target_country}?convert_quantity=${_ConvertedAmountRequest.convertableAmount}`,
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      commit("getConvertedAmount", {
        type: "GET_CONVERTED_AMOUNT",
        data: responseData,
      });
      return responseData;
    } catch (e) {
      return e;
    }
  },
  async getConvertedTargetAmount({ commit }, _ConvertedAmountRequest) {
    try {
      const token = sessionStorage.getItem("access_token");
      const responseData = await axios.get(
        `${process.env.VUE_APP_TRANSACATION_SERVICE}/api/v1/exchangerates/convert/reverse/${_ConvertedAmountRequest.base_currency}/${_ConvertedAmountRequest.to_currency}?convert_quantity=${_ConvertedAmountRequest.convertableAmount}`,
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      commit("getConvertedTargetAmount", {
        type: "GET_CONVERTED_FROM_AMOUNT",
        data: responseData,
      });
      return responseData;
    } catch (e) {
      return e;
    }
  },
  async getNationalityList({ commit }) {
    try {
      const token = sessionStorage.getItem("access_token");
      const responseData = await axios.get(
        `${process.env.VUE_APP_COMMON_SERVICE}/api/v1/dropdown/get-all-country-data`,
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      commit("getNationalityList", {
        type: "GET_NATIONALITY_LIST",
        data: responseData.data,
      });

      return responseData;
    } catch (e) {
      return e;
    }
  },
  async getOccupationsList({ commit }) {
    try {
      const token = sessionStorage.getItem("access_token");
      const responseData = await axios.get(
        `${process.env.VUE_APP_TRANSACATION_SERVICE}/api/v1/sub_category/occupations`,
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      commit("getOccupationsList", {
        type: "GET_OCCUPATION_LIST",
        data: responseData,
      });

      return responseData;
    } catch (e) {
      return e;
    }
  },

  async getUserRemitteList({ commit }) {
    try {
      const token = sessionStorage.getItem("access_token");
      const responseData = await axios.get(
        `${process.env.VUE_APP_REMITTE_USER_SERVICE}/api/v1/remitte_details/remitter/get_remittee_list`,
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      commit("getUserRemitteList", {
        type: "GET_REMITTE_LIST",
        data: responseData,
      });

      return responseData;
    } catch (e) {
      return e;
    }
  },
  async submitOnBehalfDetails({ commit }, data) {
    try {
      const token = sessionStorage.getItem("access_token");
      const responseData = await axios.post(
        `${process.env.VUE_APP_BUSINESS_USER_SERVICE}/api/v1/on_behalf/register`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );
      commit("submitOnBehalfDetails", {
        type: "SAVE_ONBEHALF",
        data: responseData,
      });
      return responseData;
    } catch (e) {
      return e;
    }
  },

  async getRemitteCountries({ commit }) {
    try {
      const token = sessionStorage.getItem("access_token");
      const responseData = await axios.get(
        `${process.env.VUE_APP_TRANSACATION_SERVICE}/api/v1/service_charge/country_list`,
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      commit("getRemitteCountries", {
        type: "GET_REMITTE_COUNTRIES",
        data: responseData,
      });
      return responseData;
    } catch (e) {
      return e;
    }
  },

  async getRemitteMethods({ commit }, _country) {
    try {
      const token = sessionStorage.getItem("access_token");
      const responseData = await axios.get(
        `${process.env.VUE_APP_TRANSACATION_SERVICE}/api/v1/service_charge/get_all_by_country/${_country}`,
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      commit("getRemitteMethods", {
        type: "GET_REMITTE_METHODS",
        data: responseData,
      });
      return responseData;
    } catch (e) {
      return e;
    }
  },
  //get-service-charge/country
  async getRemitteServiceCharge({ commit }, _getRemitteServiceCharge) {
    try {
      const token = sessionStorage.getItem("access_token");
      const responseData = await axios.get(
        `${process.env.VUE_APP_TRANSACATION_SERVICE}/api/v1/service_charge/get-service-charge/country/${_getRemitteServiceCharge.countryCode}/${_getRemitteServiceCharge.methodCode}`,
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      commit("getRemitteServiceCharge", {
        type: "GET_REMITTE_SERVICE_CHARGE",
        data: responseData,
      });
      return responseData;
    } catch (e) {
      return e;
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
