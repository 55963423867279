// src/plugins/vuetify.js

import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi", // default - only for display purposes
  },

  theme: {
    themes: {
      light: {
        primary: "#ff6b00",
        secondary: "#606d79",
        // anchor: "#ff009d",
      },
      dark: {
        primary: colors.blue.lighten3,
      },
    },
  },
});
